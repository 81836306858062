@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin verticalCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontalCenter {
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// Bootstrap 5 aligned
@mixin respondBreakpoint($breakpoint) {
  @if $breakpoint == xs {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
      @content;
    }
  }
  @if $breakpoint == xxl {
    @media only screen and (min-width: 1400px) {
      @content;
    }
  }
}

@mixin respond($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @include respondBreakpoint($breakpoint) {
      @content;
    }
  }
}

@mixin fontAwesomeStyles() {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 6 Pro", sans-serif;
}
