@import "variables";

lib-generic-select-form-control {
  .select-placeholder {
    color: $gray-600;
  }

  fa-icon {
    color: $primary;

    &:hover {
      color: $btn-link-hover-color;
    }
  }
}
