@import "variables";
@import "mixins";

*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

input[type="number"] {
  width: 100% !important;
  appearance: textfield;
  margin-right: 2px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

[placeholder] {
  text-overflow: ellipsis;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

body.scroll-disabled {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

@include respond(xl, xxl) {
  .container {
    max-width: 1092px;
  }
}

@include respond(xs ) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $teal;
}

label {
  margin-bottom: .5rem;
}

hr {
  color: $gray-400;
}
