/* stylelint-disable property-no-vendor-prefix */
@import "variables";
@import "mixins";

// TODO uncomment if needed for radio later, otherwise rm
// input[type="radio"] + label {
//   // position: absolute;
//   // top: 0;
//   // left: 0;
//   font-weight: 400;
//   padding: 0 2rem;
//   color: $dark;
//   transition: all .3s;
//   margin-bottom: 0;

//   // &--radio {
//   margin-right: 3rem;
//   font-size: inherit;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   flex: 0 0 100%;
// }

// .radio-button {
//   height: 1.5rem;
//   width: 1.5rem;
//   border: 2px solid;
//   border-radius: 50%;
//   position: relative;
//   margin-right: 1rem;
//   display: flex;
//   flex: 0 0 1.5rem;
//   align-items: center;
//   justify-content: center;

//   &::after {
//     content: "";
//     display: none;
//     width: 1rem;
//     height: 1rem;
//     border-radius: 50%;
//     background-color: $secondary;
//   }
// }

// input[type="radio"]:checked + label .radio-button {
//   border-color: $primary;
// }

// input[type="radio"]:checked + label .radio-button::after {
//   display: block;
// }

input[type="checkbox"] {
  height: 25px;
  width: 25px;
  margin-bottom: 5px;
  margin-right: .2rem;
}
