@import "variables";
@import "mixins";

.nav-tabs {
  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 190px;
      font-family: $font-family-sans-serif;
      font-size: 1rem;
      color: $gray-800;

      @include respond(xs) {
        width: 140px;
      }
    }

    .nav-link.active,
    .nav-link:hover {
      font-weight: bold;
      border-bottom: 2px solid $primary !important;
      border-radius: unset;
      border: 0;
      outline: none !important;
      box-shadow: none !important;
      line-height: unset;
    }
  }
}
