/* stylelint-disable max-line-length */
// ----------------------
// Bootstrap 5 defaults:
// ----------------------
// see node_modules\bootstrap\scss\_variables.scss

// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;

// $blue:    #0d6efd !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #d63384 !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #198754 !default;
// $teal:    #20c997 !default;
// $cyan:    #0dcaf0 !default;

// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// $font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// ----------------------

@import "../base/fonts";

// Site fonts:
$font-family-montserrat: MontserratRegular, sans-serif;
$font-family-montserrat-bold: MontserratBold, sans-serif;
$font-family-montserrat-light: MontserratLight, sans-serif;
$font-family-montserrat-semibold: MontserratSemiBold, sans-serif;

// Site colors:
// #0a2240; // body color
// #528387; // h titles, label
// #f37120; // button color
// #f5f5f5; // form bg
// #c4c2c2; // dropshadow
// #e3e3e3; // footer bg
// #6f7170; // icon, footer text, small note text
// #84909f; // placeholder

// Boostrap overrides:

// Colors
$white:       #fff;
$gray-100:    #e6e2e4; // form bg
$gray-200:    #e3e3e3; // footer bg
$gray-300:    #ddd;
$gray-400:    #c4c2c2; // dropshadow
$gray-500:    #84909f; // placeholder
$gray-600:    #6f7170; // icon, footer text, small note text
$gray-700:    #495057;
$gray-800:    #343a40;
$gray-900:    #212529;
$gray-h1:     #1e0013;
$black:         #000;

$blue:        #899add;
$indigo:      #6610f2;
$purple:      #8246af;
$dark-purple: #7a126b;
$light-purple: #d9c1d8;
$darker-purple: #1a1237;
$grey-purple: #4e444e;
$pink:        #d63384;
$light-red:   #ff7675;
$red:         #e1251b;
$orange:      #f37120;
$yellow:      #fee600;
$dark-yellow: #ffb43d;
$green:       #6ac346;
$teal:        #333f48;
$cyan:        #0dcaf0;

$blue-gray:     #333f48; // body text color

$primary:       $dark-purple;    // main accent color, color of buttons etc.
$secondary:     $teal;  // the most prominent color, text, backgrounds, panels etc.
$success:       $dark-purple;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$font-family-sans-serif: $font-family-montserrat;
$font-family-bold: $font-family-montserrat-bold;
$font-family-light: $font-family-montserrat-light;

// Headings
$headings-font-family:        $font-family-montserrat;
$headings-color:              $gray-h1;
$h5-font-size:            1.375rem;
$h6-font-size:            1.125rem;

// Buttons
$input-btn-font-family: $font-family-montserrat;

// Forms
$input-border-color:  $gray-300;
$input-border-radius:  5px;

$form-label-font-size:    .875rem;
$form-label-color:        $teal;

// Offcanvases
$offcanvas-padding-x: 2rem;
$offcanvas-backdrop-opacity: .05;

// Quiz
$quiz-flow-background: #e5e2e4;
