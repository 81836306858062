@import "variables";
@import "mixins";

.btn-primary,
.btn-outline-primary {
  padding: 8px 10px;
  width: auto;

  @include respond(sm,md) {
    width: auto;
  }

  @include respond(xs) {
    min-width: 100%;
  }
}

.btn-search {
  padding: 4px 50px;
}

.btn-group {
  padding: 4px 30px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: $white !important;
}

.btn-outline-primary {
  color: $primary !important;

  &:hover,
  &:focus-visible {
    color: $white !important;
  }
}
